<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <ValidationProvider name="Organization" vid="org_id">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="org_id"
                            >
                            <template v-slot:label>
                            {{$t('org_pro.organization')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="search.org_id"
                            :options="orgList"
                            id="org_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('germConfig.cropType')"
                        label-for="email"
                        >
                        <v-select name="croptypeID"
                        v-model="search.crop_type_id"
                        label="text"
                        :options= CropTypeList
                        />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('germplasm.passport_data')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <!-- <export-excel
                            class="btn btn-success mr-2"
                            :data="dataCustomizeExcel"
                            :fields="json_fields"
                            type = "xls"
                            worksheet="Germplasn Production Report"
                            name="demo.xls">
                            {{ $t('globalTrans.export_excel') }}
                        </export-excel> -->
                        <b-button variant="primary" class="text-center mr-2">
                             <a :href="baseUrl + ExcelExport"  class="mr-3">{{ $t('globalTrans.export_pdf') }}</a>
                        </b-button>
                        <b-button class="text-center mr-2" v-b-modal.modal-5>
                             {{  $t('globalTrans.excelImport') }}
                        </b-button>
                        <b-button variant="primary">
                            <router-link :to="{ path: '/seeds-fertilizer-service/germplasm-germplasm/passport'}" size="sm">
                                {{  $t('globalTrans.add_new') }}
                            </router-link>
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(crop_name)="data">
                                        <span class="capitalize">{{ data.item.crop_name }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button variant=" iq-bg-success mr-1 mb-1" size="sm">
                                                <router-link :to="{ path: '/seeds-fertilizer-service/germplasm-germplasm/passport', query: { id: data.item.id }}" size="sm">
                                                        <i class="ri-ball-pen-fill m-0"></i>
                                                </router-link>
                                            </b-button>
                                            <span v-if="data.item.status === 1">
                                                <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fas fa-window-close m-0"></i></b-button>
                                            </span>
                                            <span v-if="data.item.status === 2">
                                                <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fa fa-check m-0"></i></b-button>
                                            </span>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <XlForm :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import XlForm from './ExcelForm'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { collectionList, collectionToggleStatus, passportDataExport } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  'Collection Date': 'collectionDate',
  'Collection No': 'collectionNo',
  'Collector Number': 'collectorNumber',
  'Crop Type': 'cropType',
  'Crop Name': 'cropName',
  'Local Name': 'localName',
   'Local Name Bn': 'localNameBn',
   'Cultivar Name': 'cultiverName',
   'Cultivar Name Bn': 'cultiverNameBn',
  'Area Type': 'areaType',
  'City Corporation': 'cityCorporation',
  Pauroshoba: 'pauroshoba',
  Division: 'division',
  District: 'district',
  Upazilla: 'upazilla',
  Union: 'union',
  Ward: 'ward',
  Address: 'address',
  'Address Bn': 'addressBn',
  Latitude: 'latitude',
  Longitude: 'longitude',
  Attachment: 'attachment',
  'Collector Name': 'collectorName',
  'Collector Name Bn': 'collectorNameBn',
  Remarks: 'remarks',
  'Remarks Bn': 'remarksBn',
  Expedition: 'expedition',
  'Map Sheet': 'mapSheet',
  Altitude: 'altitude',
  'Collection Source': 'collectionSource',
  Topography: 'topography',
  Site: 'site',
  'Soil Texture': 'soilTexture',
  Drainage: 'drainage',
  'Sampling Method': 'samplingMethod',
  Herbarium: 'herbarium',
  'Status of Sample': 'statusOfSample',
  'Farmer Name': 'farmerName',
  'Farmer Name Bn': 'farmerNameBn',
   Language: 'language',
  'Meaning of Name': 'meaningOfName',
  'Meaning of Name Bn': 'meaningOfNameBn',
  'Varietal Sample': 'varietalSample',
  'Varietal Group': 'varietalGroup',
  Origin: 'origin',
  Frequency: 'frequency',
  'Cultural Type': 'culturalType',
  'Cultural Practices': 'culturalPractices',
  'Showing Date': 'showingDate',
  'Transplanting Date': 'transplantingDate',
  'Harvest Date': 'harvestDate',
  Maturity: 'maturity',
  Usage: 'usage',
  'Farmer Brief Characterization': 'farmerBriefChar',
  'Farmer Brief Description': 'farmerBriefDes',
  'Farmer Matching Cultivars Micro Env': 'farmerMicroEnv',
  'Farmer Matching Cultivars Mng Practices': 'farmerMngPractices',
  'Farmer Matching Mng Practices Env': 'farmerPracticesEnv',
  'Special Traits': 'specilaTraits',
  'Area Distribution Importance': 'areImportance',
  'Seed Exchange': 'seedExchange',
  'Gender Issue': 'genderIssue',
  'Created By': 'createdBy',
  'Updated By': 'updatedBY',
  Status: 'status'
}

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form,
        XlForm
    },
    data () {
        return {
            search: {
                crop_type_id: 0,
                org_id: this.$store.state.dataFilters.orgId
            },
            baseUrl: seedFertilizerServiceBaseUrl,
            ExcelExport: passportDataExport,
            dataCustomizeExcel: [
                {
                    collectionDate: '2021-06-23',
                    collectionNo: 155,
                    collectorNumber: 101,
                    cropType: 1,
                    cropName: 42,
                    localName: 'Lesley Barber',
                    localNameBn: 'Tate Rojas',
                    cultiverName: 'Simone Vazquez',
                    cultiverNameBn: 'Simone Vazquez bn',
                    areaType: 1,
                    cityCorporation: 2,
                    pauroshoba: 'NULL',
                    division: 1,
                    district: 1,
                    upazilla: 'NULL',
                    union: 'NULL',
                    ward: 38,
                    address: '',
                    addressBn: '',
                    latitude: '23551552',
                    longitude: '54687464',
                    attachment: 'NULL',
                    collectorName: '',
                    collectorNameBn: '',
                    remarks: '',
                    remarksBn: '',
                    expedition: 'Test Expedition',
                    mapSheet: 'Test Sheet',
                    altitude: '254.687.954',
                    collectionSource: '1,2',
                    topography: '2,3',
                    site: '2,3',
                    soilTexture: '4',
                    drainage: '1,3',
                    samplingMethod: '2',
                    herbarium: '1,2',
                    statusOfSample: '1,2',
                    farmerName: 'Jhon Wick',
                    farmerNameBn: 'Jhon Wick BN',
                    language: 'Bangle',
                    meaningOfName: 'NA',
                    meaningOfNameBn: 'NA',
                    varietalSample: '1,2',
                    varietalGroup: '2,3',
                    origin: '1,3',
                    frequency: '1',
                    culturalType: '1,2',
                    culturalPractices: '2',
                    showingDate: '2021-06-23',
                    transplantingDate: '2021-06-23',
                    harvestDate: '2021-06-23',
                    maturity: 'Test',
                    usage: 'Test',
                    farmerBriefChar: 'Tate Rojas',
                    farmerBriefDes: 'Rojas',
                    farmerMicroEnv: 'Adipisci sit nemo ip',
                    farmerMngPractices: 'Facere non qui persp',
                    farmerPracticesEnv: 'Excepteur aut et mol',
                    specilaTraits: 'Et qui tempore cill',
                    areImportance: 'Ullam aut maxime mol',
                    seedExchange: 'Et cillum dicta ipsa',
                    genderIssue: 'Incidunt sapiente v',
                    createdBy: 1,
                    updatedBY: 1,
                    status: 1
                }
            ]
        }
    },
    computed: {
        json_fields: function () {
          return excelColumn
        },
        CropTypeList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
        },
        cropNameList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('germplasm.passport_data') + ' ' + this.$t('globalTrans.entry') : this.$t('germplasm.passport_data') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('seedsSeeds.organization'), class: 'text-left' },
                { label: this.$t('germplasm.collection_date'), class: 'text-left' },
                { label: this.$t('germplasm.collector_number'), class: 'text-left' },
                { label: this.$t('germplasm.crop'), class: 'text-left' },
                { label: this.$t('germplasm.local_name'), class: 'text-left' },
                { label: this.$t('germplasm.cultivar_name'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'org_name_bn' },
                { key: 'collection_date' },
                { key: 'collector_number' },
                { key: 'crop_name_bn' },
                { key: 'local_name_bn' },
                { key: 'cultivar_name_bn' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'org_name' },
                { key: 'collection_date' },
                { key: 'collector_number' },
                { key: 'crop_name' },
                { key: 'local_name' },
                { key: 'cultivar_name' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {

    },
    methods: {
        dataChange () {
          this.loadData()
        },
        async searchData () {
          this.loadData()
        },
        remove (item) {
          this.changeStatus(seedFertilizerServiceBaseUrl, collectionToggleStatus, item, 'seeds_fertilizer', 'CropNameList')
        },
        loadData () {
            var cropIdSerach = ''

            if (this.search.cropID) {
                cropIdSerach = this.search.cropID.value
            }
            var croptypeIdSerach = ''

            if (this.search.crop_type_id) {
                croptypeIdSerach = this.search.crop_type_id.value
            }

            const params = Object.assign({}, this.search, { cropID: cropIdSerach, crop_type_id: croptypeIdSerach, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, collectionList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList(data) {
          const listData = data.map(item => {
            const cropNameObj = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(doc => doc.value === parseInt(item.crop_name_id))
            const cropNameData = {}
            if(typeof cropNameObj !== 'undefined') {
              cropNameData.crop_name = cropNameObj.text_en
              cropNameData.crop_name_bn = cropNameObj.text_bn
            } else {
              cropNameData.crop_name = ''
              cropNameData.crop_name_bn = ''
            }
            const OrgObj = this.$store.state.commonObj.organizationProfileList.find(listitem => listitem.value === item.org_id && listitem.status === 0)
            const orgData = {}
            if(typeof OrgObj !== 'undefined') {
              orgData.org_name = OrgObj.text_en
              orgData.org_name_bn = OrgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }

            return Object.assign({}, item, cropNameData, orgData)
          })
          return listData
        }
    }
}
</script>
