<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="10" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <ValidationProvider name="Attachment file" vid="excel_data" rules="">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="warehouse_attachments"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('globalTrans.excelFile')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-file
                        id="excel_data"
                        v-on:change="onFileChange"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-file>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2" :disabled="isLoading">
                            <span v-if="isLoading"><b-spinner small></b-spinner> {{ $t('globalTrans.loading') }}</span>
                            <span v-else>{{ saveBtnName }}</span>
                            </b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { passportDataImport } from '../../api/routes'
import { SpinnerPlugin } from 'bootstrap-vue'
import Vue from 'vue'
Vue.use(SpinnerPlugin)

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.save'),
      excel_data: '',
      attachment_two: '',
      isLoading: false
    }
  },
  methods: {
    onFileChange (e) {
      this.attachment_two = e.target.files[0]
    },
     async createData () {
         this.isLoading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      formData.append('excel_file', this.attachment_two)

        result = await RestApi.postData(seedFertilizerServiceBaseUrl, passportDataImport, formData, config)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.excelImportMessage'),
          color: '#D6E09B'
        })
         this.isLoading = false
        this.$bvModal.hide('modal-5')
      } else {
            this.$toast.error({
                title: 'Error',
                message: result.message,
                color: '#D6E09B'
            })
      }
    }
  }
}
</script>
